import React, { Suspense } from "react";
import ls from "local-storage";
import Splash from "./compontents/Splash";
import Header from "./compontents/Header";
import Footer from "./compontents/Footer";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import {
  Home,
  About,
  Contact,
  Portfolio,
  NotFound,
  ProposalsOverview,
  EditProposal,
  ViewProposal,
} from "./pages";

function App() {
  return (
    <Suspense fallback={<div></div>}>
      <React.Fragment>
        {process.env.REACT_APP_SHOW_SPLASH === "true" &&
          !ls.get("splashHadBeenViewed") && (
            <Splash marginTop={20} logoWidth={125} />
          )}
        <Router basename="/">
          <Header />
          <div style={{ padding: "10px 20px 30px" }}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/portfolio" component={Portfolio} />
              <Route exact path="/about" component={About} />
              <Route exact path="/contact" component={Contact} />
              <Route
                exact
                path="/proposals/overview"
                component={ProposalsOverview}
              />
              <Route exact path="/proposals/:id" component={EditProposal} />
              <Route exact path="/view-proposal/:id" component={ViewProposal} />
              <Route path="/not-found" component={NotFound} />
              <Route>
                <Redirect to="/not-found" />
              </Route>
            </Switch>
          </div>
          <Footer />
        </Router>
      </React.Fragment>
    </Suspense>
  );
}

export default App;
