import { lazy } from "react";
import Home from "./Home";
import Portfolio from "./Portfolio";
import About from "./About";
import Contact from "./Contact";
import NotFound from "./NotFound";
const ProposalsOverview = lazy(() => import("./Proposals"));
const EditProposal = lazy(() => import("./EditProposal"));
const ViewProposal = lazy(() => import("./ViewProposal"));

export {
  Home,
  Contact,
  About,
  Portfolio,
  NotFound,
  ProposalsOverview,
  EditProposal,
  ViewProposal,
};
